<!-- ======= About Section ======= -->
<section *ngIf="_about.about" id="about" class="about">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>About</h2>
      <p *ngIf="_about.about.descripcion">{{ _about.about.descripcion }}</p>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <img src="{{ _about.about.imagen }}" class="img-fluid" alt="" />
      </div>
      <div class="col-lg-8 pt-4 pt-lg-0 content">
        <h3>{{ _about.about.titulo }}</h3>
        <p *ngIf="_about.about.descripcion2" class="font-italic">{{ _about.about.descripcion2 }}</p>
        <div class="row">
          <div class="col-lg-6">
            <ul>
              <li *ngIf="_about.about.birthday">
                <em class="icofont-rounded-right"> </em> <strong>Birthday: </strong>
                {{ _about.about.birthday }}
              </li>
              <li *ngIf="_about.about.website">
                <em class="icofont-rounded-right"></em> <strong>Website: </strong>
                <a href="{{ _about.about.website  }}">{{ _about.about.website }}</a>
              </li>
              <li *ngIf="_about.about.phone">
                <em class="icofont-rounded-right"></em> <strong>Phone: </strong>{{ _about.about.phone }}
              </li>
              <li>
                <em class="icofont-rounded-right"></em> <strong>City: </strong>
                <span *ngIf="_about.about.city">{{ _about.about.city | titlecase }}</span>
                <span *ngIf="_about.about.country">, {{ _about.about.country }}</span>
              </li>
            </ul>
          </div>
          <div class="col-lg-6">
            <ul>
              <li *ngIf="_about.about.age">
                <em class="icofont-rounded-right"></em> <strong>Age: </strong>{{ _about.about.age }}
              </li>
              <li *ngIf="_about.about.degree">
                <em class="icofont-rounded-right"></em> <strong>Degree: </strong>{{ _about.about.degree }}
              </li>
              <li *ngIf="_about.about.email">
                <em class="icofont-rounded-right"></em> <strong>Email: </strong>{{ _about.about.email }}
              </li>
              <li *ngIf="_about.about.freelance">
                <em class="icofont-rounded-right"></em> <strong>Freelance:</strong>{{ _about.about.freelance }}
              </li>
            </ul>
          </div>
        </div>
        <p *ngIf="_about.about.descripcion3">{{ _about.about.descripcion3 }}</p>
      </div>
    </div>

  </div>
</section><!-- End About Section -->
