<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="container">
    <h3>{{ _hero.hero.nombre }}</h3>
    <p *ngIf="_about.about.titulo"><span>{{ _about.about.titulo }}</span></p>
    <div class="social-l">
      <a *ngFor="let item of _social.social['social']" [attr.href]="item.url" [attr.class]="item.icon">
        <fa-icon [icon]="['fab', item.icon]"></fa-icon>
      </a>
    </div>
    <div class="copyright">
      &copy; Copyright <strong><span>MyResume</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
      <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: [license-url] -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/free-html-bootstrap-template-my-resume/ -->
      Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
    </div>
  </div>
</footer><!-- End Footer -->
