.<!-- ======= Skills Section ======= -->
<section id="skills" class="skills section-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>Skills</h2>
      <p>{{_about.about.skills?.descripcion}}</p>
    </div>

    <div class="row skills-content">
      <div class="col-lg-6" *ngFor="let item of _about.about.skills?.skill">
        <div class="progress">
          <span class="skill">{{ item.skill }}<em class="val">{{ item.porcentaje }}</em></span>
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
              [attr.aria-label]="item.skill" [style.width]="item.porcentaje"
              [attr.aria-valuenow]="item.porcentaje" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section><!-- End Skills Section -->
