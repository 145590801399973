<app-header></app-header>

<!-- ======= Hero Section ======= -->
<app-hero></app-hero>
<!-- End Hero -->

<main id="main">
  <app-about></app-about>
  <app-facts></app-facts>
  <app-skills></app-skills>
  <app-resume></app-resume>
  <!-- <app-portfolio></app-portfolio> -->
  <!-- <app-services></app-services> -->
  <!-- <app-testimonials></app-testimonials> -->
  <!-- <app-contact></app-contact> -->
</main>

<app-footer></app-footer>
