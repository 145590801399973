
  <!-- ======= Mobile nav toggle button ======= -->
  <button type="button" class="mobile-nav-toggle d-xl-none"><em class="icofont-navigation-menu"></em></button>

  <!-- ======= Header ======= -->
  <header id="header" class="d-flex flex-column justify-content-center">

    <nav class="nav-menu">
      <ul>
        <li class="active"><a href="#hero"><em class="bx bx-home"></em> <span>Home</span></a></li>
        <li><a href="#about"><em class="bx bx-user"></em> <span>About</span></a></li>
        <li><a href="#resume"><em class="bx bx-file-blank"></em> <span>Resume</span></a></li>
        <!-- <li><a href="#portfolio"><em class="bx bx-book-content"></em> <span>Portfolio</span></a></li> -->
        <!-- <li><a href="#services"><em class="bx bx-server"></em> <span>Services</span></a></li> -->
        <!-- <li><a href="#contact"><em class="bx bx-envelope"></em> <span>Contact</span></a></li> -->
      </ul>
    </nav><!-- .nav-menu -->

  </header><!-- End Header -->
